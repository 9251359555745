/* Computer screens */
@media screen and (min-width: 769px) {
  .typing-effect-container {
    height: 12vh;
  }
}

/* Phone screens */
@media screen and (max-width: 768px) {
  .typing-effect-container {
    height: 30vh;
  }
}

h2 {
  text-align: left;
  padding-left: 10vw;
  padding-right: 5vw;
}

h4 {
  text-align: left;
  padding-left: 11vw;
  padding-right: 5vw;
}
