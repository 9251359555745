.portfolio-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  padding: 0 20px;
}

.portfolio-card {
  background-color: var(--light-theme-bg-color);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  color: var(--light-theme-text-color);
  min-width: 300px;
  max-width: 45%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.featured-portfolio-card {
  background-color: var(--light-theme-bg-color);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  color: var(--light-theme-text-color);
  min-width: 300px;
  max-width: 45%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content h5 {
  margin-top: auto;
}

.portfolio-card img {
  width: 80%;
  max-width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.featured-portfolio-card img {
  width: 80%;
  max-width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.card-link-icon {
  float: right;
  text-decoration: none;
  font-size: 1.2em;
  margin-left: 10px;
  background: none;
  cursor: pointer;
  transition: font-size 0.3s ease;
}

.card-link-icon:hover {
  text-decoration: none;
  font-size: 1.4em;
}

body.dark-mode .portfolio-card {
  background-color: var(--dark-theme-bg-color);
  border: 1px solid #555;
  color: var(--dark-theme-text-color);
}

body.dark-mode .featured-portfolio-card {
  background-color: var(--dark-theme-bg-color);
  border: 1px solid #555;
  color: var(--dark-theme-text-color);
}

.portfolio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.featured-portfolio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.card-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.card-content p {
  text-align: left;
}

.card-content h5 {
  text-align: center;
}

.toggle-arrow {
  margin-left: 10px;
  user-select: none;
}

.card-link-icon {
  float: none;
  text-decoration: none;
  font-size: 1.2em;
  margin-left: 10px;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
}

.card-link-icon:hover {
  text-decoration: underline;
}

.card-expanded .card-content {
  max-height: 80vh;
}

.card-header {
  position: relative;
  text-align: center;
  padding: 15px 0;
}

.card-header h3 {
  margin: 0;
  display: inline-block;
}

.date {
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.9em;
  color: #666;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.title-container h3 {
  margin: 0;
  text-align: center;
}

.card-link-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 1000px) {
  .portfolio-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .portfolio-card img {
    max-width: 100%;
  }

  .toggle-arrow {
    display: none;
  }

  .card-content {
    max-height: none;
  }

  .date {
    display: block; /* show dates on desktop/expanded view */
  }
}

@media screen and (min-width: 769px) {
  .text-container {
    padding-left: 20vw;
    padding-right: 20vw;
  }
}

@media screen and (max-width: 768px) {
  .text-container {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
