.desktop-nav {
  position: fixed;
  height: 2em;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

body.dark-mode .desktop-nav {
  background-color: var(--dark-theme-bg-color);
  box-shadow: 0 2px 4px rgb(255 255 255 / 30%);
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.nav-link,
.nav-link-right {
  cursor: pointer;
  margin: 0 15px;
  font-size: 1.25em;
  color: var(--light-theme-text-color);
}

body.dark-mode .nav-link,
body.dark-mode .nav-link-right {
  color: var(--dark-theme-text-color);
}

.nav-link:hover,
.nav-link-right:hover {
  color: #0056b3;
}

body.dark-mode .nav-link:hover,
body.dark-mode .nav-link-right:hover {
  color: var(--dark-theme-link-hover-color);
}

.active {
  font-weight: bold;
  color: var(--light-theme-text-color);
}

body.dark-mode .active {
  color: var(--dark-theme-link-color);
}

.icon-links {
  display: flex;
  gap: 15px;
}

.fa-icon {
  font-size: 24px;
  cursor: pointer;
  transition:
    color 0.3s,
    transform 0.3s;
}

.fa-icon:hover {
  transform: scale(1.5);
}

.nav-icon {
  font-size: 24px;
  color: var(--light-theme-text-color);
  margin-left: 20px;
  cursor: pointer;
  transition:
    color 0.3s,
    transform 0.3s;
}

body.dark-mode .nav-icon {
  color: var(--dark-theme-text-color);
}

.nav-icon:hover {
  transform: scale(1.5);
}

.nav-icon.github:hover {
  color: #6e5494;
}

.nav-icon.linkedin:hover {
  color: #0077b5;
}

.nav-icon.pdf:hover {
  color: red;
}

.toggle-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.toggle-wrapper {
  position: relative;
  z-index: 1;
  width: 60px;
  height: 30px;
  display: inline-block;
  background-color: #678da2;
  border-radius: 15px;
}

.toggle-label {
  width: 60px;
  height: 30px;
  background-color: var(--light-theme-bg-color);
  border-radius: 15px;
  opacity: 1;
  visibility: visible;
  z-index: 10;
  border: none;
}

body.dark-mode .toggle-label {
  background-color: var(--dark-theme-bg-color);
}

.toggle-switch {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: var(--dark-theme-text-color);
  border: 1px solid #aaa;
  border-radius: 50%;
  transition: transform 0.3s;
}

body.dark-mode .toggle-switch {
  background-color: var(--dark-theme-text-color);
}

.toggle-input {
  display: none;
}

.toggle-input:checked + .toggle-label .toggle-switch {
  transform: translateX(28px);
}

.toggle-emoji {
  font-size: 20px;
  transition: font-size 0.3s;
}

.toggle-input:not(:checked) ~ .light-emoji {
  font-size: 30px;
}

.toggle-input:checked ~ .dark-emoji {
  font-size: 30px;
}

.enlarged {
  transform: scale(1.5);
}

@media screen and (min-width: 769px) {
  .bm-burger-button {
    display: none;
  }
}

.bm-burger-button {
  position: relative;
  width: 26px;
  height: 20px;
  background-color: transparent;
}

.bm-burger-button span {
  background-color: var(--light-theme-text-color);
}

body.dark-mode .bm-burger-button span {
  background-color: var(--dark-theme-text-color);
}

.bm-burger-bars {
  background-color: var(--light-theme-text-color) !important;
}

body.dark-mode .bm-burger-bars {
  background-color: var(--dark-theme-text-color) !important;
}
.bm-overlay {
  margin-top: 2.5em !important;
  height: 200% !important;
  width: 200% !important;
  background-color: var(--light-theme-bg-color);
}

body.dark-mode .bm-overlay {
  background-color: var(--dark-theme-bg-color);
}

.bm-menu-wrap {
  width: 50% !important;
  background-color: #fff;
  padding: 1em;
  margin-left: -2em;
}

body.dark-mode .bm-menu-wrap {
  background-color: var(--dark-theme-bg-color);
}

.bm-item-list {
  padding: 1em;
  padding-top: 10vh;
  color: var(--light-theme-text-color);
}

body.dark-mode .bm-item-list {
  color: var(--dark-theme-text-color);
}

.bm-item {
  padding: 2em;
  align-self: center;
  color: var(--light-theme-text-color);
}

body.dark-mode .bm-item {
  color: var(--dark-theme-text-color);
}

.bm-item-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4em;
  padding-left: 0.2em;
  color: var(--light-theme-text-color);
}

body.dark-mode .bm-item-bottom {
  color: var(--dark-theme-text-color);
}
