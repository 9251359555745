.site-container {
  text-align: center;
  padding-top: 80px;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.main-link {
  color: var(--link-color);
}

.section-fullscreen {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Arial", sans-serif;

  overflow-x: auto;
  scrollbar-width: none; /* Disable horizontal scrollbar for Firefox */
}

.section-fullscreen::-webkit-scrollbar {
  display: none; /* Disable horizontal scrollbar for Chrome, Safari and Opera */
}
