form {
  width: 100%;
  max-width: 600px;
  background: #b2bfc6;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

body.dark-mode form {
  background: var(--dark-theme-bg-color);
  border: 1px solid #555;
}

body.dark-mode a[href^="mailto:"] {
  color: var(--dark-theme-email-link-color);
}

form input,
form textarea,
form button {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

body.dark-mode form input,
body.dark-mode form textarea,
body.dark-mode form button {
  background: #565656;
  color: var(--dark-theme-text-color);
}

form input::placeholder,
form textarea::placeholder {
  color: #999;
  font-weight: 300;
}

body.dark-mode form input::placeholder,
body.dark-mode form textarea::placeholder {
  color: #bbb;
}

form textarea {
  resize: none;
  height: 150px;
}

form button {
  background-color: #678da2;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

body.dark-mode form button {
  background-color: #444;
  color: #fff;
}

form button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

body.dark-mode form button:hover {
  background-color: #333;
}

form button:active {
  transform: translateY(0px);
}
