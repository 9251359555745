/* Computer screens */
@media screen and (min-width: 769px) {
  .about-container {
    width: 100%;
    padding-left: 20vw;
    padding-right: 20vw;
  }
}

/* Phone screens */
@media screen and (max-width: 768px) {
  .about-container {
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
