/* Define color variables for the light theme */
:root {
  --light-theme-bg-color: #f5f5f5;
  --light-theme-text-color: #333;
  --light-theme-link-color: #61dafb;

  /* And dark theme as well */
  --dark-theme-bg-color: #000000;
  --dark-theme-text-color: #f5f5f5;
  --dark-theme-link-color: #61dafb;
  --dark-theme-email-link-color: #61dafb;
  --dark-theme-link-hover-color: #61dafb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--light-theme-text-color);
  background-color: var(--light-theme-bg-color);
}

body.dark-mode {
  color: var(--dark-theme-text-color);
  background-color: var(--dark-theme-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
